import { makeHeaders } from '@api/apiFetcher'

const openProtectedFile = ({ path, auth }) =>
    fetch(path, {
        headers: makeHeaders(auth),
        method: 'GET',
    })
    .then(res => res.blob())
    .then(blob => {
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.target = '_blank'
        document.body.appendChild(a)
        a.click()
        a.remove()
    })

export default openProtectedFile