import Link from 'next/link'
import ImageWrapper from '@components/image/WrapperAlt'
import AdvancedImage from '@components/image/WithFallback'
import Icon from '@components/shared/Icon'
import fullPhoto from '@utils/links/fullPhoto'
import partnerItemLink from '@utils/links/partnerItemLink'
import openProtectedFile from '@api/apiOpenProtectedFile'
import appendixLink from '@utils/links/partnerAppendixLink'
import harvestId from '@utils/dom/harvestI'
import useT from '@hooks/useTranslation'

import styles from '@components/salon/styles/PartnerListItem.module.css'

const PartnerListItem =
    (item) => {
        const { type, subdomain, slug } = item
        const link = partnerItemLink(type, subdomain, slug)
        return (
            <article>
                <Link href={link}>
                    <a>
                        <Image item={item} />
                        <Info item={item} />
                    </a>
                </Link>
                <Appendix item={item} />
                <Video item={item} link={link} />
            </article>
        )
    }


function Image ({ item: { pid, title }}) {
    return (
        <ImageWrapper>
            <AdvancedImage
                src={fullPhoto(pid, 0)}
                alt={title}
                layout="fill"
                objectFit="cover" />
        </ImageWrapper>
    )
}

function Info ({ item: { title, shortDesc }}) {
    return <>
        <h3>{title}</h3>
        <p>{shortDesc}</p>
    </>
}

function Appendix ({ item: { has_appendix, pid }}) {
    if (!has_appendix) return null
    const open = harvestId((pid) => openProtectedFile({
        path: appendixLink(pid),
        auth: '',
    }))

    return <>
        <br/><hr/>
        <button i={pid} className={`${styles.spec} bt`} onClick={open}>
            <Icon>file_present</Icon> {useT('Zobacz specyfikację')}
        </button>
    </>
}

function Video ({ item: { video }, link }) {
    if (!video) return null
    return <>
        <br/><hr/>
        <Link href={link}>
            <a className={`${styles.link} bt`}>
                <Icon>movie</Icon> {useT('Załączony film')}
            </a>
        </Link>
    </>
}

export default PartnerListItem
export { Image, Appendix }