import TYPES from '@pages/user/partner/constants/types'
import { PARTNER_PAGE_PRODUCT,
    PARTNER_PAGE_ARTICLE } from '@constants/routes'

export default function partnerItemLink (type, subdomain, slug) {
    return (type === TYPES.PRODUCT
        ? PARTNER_PAGE_PRODUCT
        : PARTNER_PAGE_ARTICLE)
            // .replace(':subdomain', subdomain)
            .replace(':slug', slug)
}