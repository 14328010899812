import Navbar from '@components/shared/Navbar'
import Footer from '@components/salon/SalonFooter'

import withBreakpoints from '@hoc/withBreakpoints'
import BREAKPOINTS from '@constants/breakpoints'

import styles from 'styles/Main.module.css'

const SalonLayout = ({ children, breakpoints }) =>
    <main className={styles.main}>
        <Navbar breakpoints={breakpoints} logoOnly="true" />
        { children }
        <Footer />
    </main>

export default withBreakpoints(BREAKPOINTS)(SalonLayout)